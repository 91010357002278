import request from '@/auth/jwt/request'

/**
 * @description 用户 -- 列表
 * @param {Object} param params {Object} 传值参数
 */
export const adminUserListApi = params => { return request('adminuser/list', 'get', params) }

/**
 * @description 用户 -- 状态修改
 * @param {Object} param params {Object} 传值参数
 */
export const adminStatusApi = param => { return request('adminuser/status', 'get', param) }

/**
 * @description 角色列表 - 用户管理中新增或编辑时候修改角色
 * @param {Object} param params {Object} 传值参数
 */
export const rolesSelectApi = () => { return request('role/select', 'get', {}) }

/**
 * @description 用户 -- 新增或修改分类
 * @param {Object} param params {Object} 传值参数
 */
export const adminUpdateApi = data => { return request('adminuser/save', 'post', {}, data) }

/**
 * @description 用户 -- 删除
 */
export const delAdminApi = data => { return request('adminuser/delete', 'DELETE', data) }

// 角色管理

/**
 * @description 角色 -- 列表
 * @param {Object} param params {Object} 传值参数
 */
export const roleListApi = params => { return request('role/list', 'get', params) }

 /**
 * @description 角色 -- 新增或编辑
 * @param {Object} param params {Object} 传值参数
 */
export const roleSaveApi = data => { return request('role/save', 'post', {}, data) }

  /**
 * @description 角色 -- 状态修改
 * @param {Object} param params {Object} 传值参数
 */
export const roleStatusApi = param => { return request('role/status', 'get', param) }

/**
 * @description 角色 -- 删除
 */
export const roleDelApi = param => { return request('role/delete', 'delete', param) }

 /**
 * @description 角色 -- 详情
 */
export const roleInfoApi = param => { return request('role/info', 'get', param) }

// 权限规则

/**
 * @description 权限规则 -- 列表
 * @param {Object} param params {Object} 传值参数
 */
export const menuListApi = params => { return request('menu/tree', 'get', params) }

 /**
 * @description 权限规则 -- 新增或编辑
 * @param {Object} param params {Object} 传值参数
 */
export const menuSaveApi = data => { return request('menu/save', 'post', {}, data) }

  /**
 * @description 权限规则 -- 状态修改
 * @param {Object} param params {Object} 传值参数
 */
export const menuStatusApi = param => { return request('menu/status', 'get', param) }

/**
 * @description 权限规则 -- 删除
 */
export const menuDelApi = param => { return request('menu/delete', 'delete', param) }

 /**
 * @description 权限规则 -- 详情
 */
export const menuInfoApi = param => { return request('menu/info', 'get', param) }

 /**
 * @description 权限规则 -- 详情
 */
export const menuSelectTreeApi = param => { return request('menu/select', 'get', param) }

 /**
 * @description 权限规则 -- 详情
 */
export const apiListApi = param => { return request('api/list', 'get', param) }

/**
 * @description 用户详情 -- 用户详情
 * @param {Object} param params {Object} 传值参数
 */
 export const adminuserInfoApi = data => { return request('adminuser/info', 'get', data) }

 /**
 * @description 设置关联项目 -- 设置关联项目
 * @param {Object} param params {Object} 传值参数
 */
export const updateProjectsApi = data => { return request('adminuser/updateProjects', 'post', {}, data) }

