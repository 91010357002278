<template>
  <div>
    <b-card no-body class="mb-1">
      <b-card-body class="pt-50">
        <b-row>
          <b-col cols="12" md="6" lg="4" class="mb-md-0 my-1">
            <b-form-group label-cols="auto" label="权限标识" style="margin-bottom: 0;">
            <b-form-input
              v-model="searchParams.apiKey"
              class="d-inline-block"
              placeholder="权限标识"
            />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="4" class="mb-md-0 my-1">
            <b-form-group label-cols="auto" label="接口名称" style="margin-bottom: 0;">
            <b-form-input
              v-model="searchParams.name"
              class="d-inline-block"
              placeholder="接口名称"
            />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="4" class="mb-md-0 my-1">
            <b-form-group label-cols="auto" label="路由标识" style="margin-bottom: 0;">
            <b-form-input
              v-model="searchParams.route"
              class="d-inline-block"
              placeholder="路由标识"
            />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card>
      <vxe-table
        ref="xTable" border :auto-resize="true" :align="allAlign" :data="tableData"
        highlight-current-row :loading="loading" :scroll-x="{ enabled: true }" :scroll-y="{ enabled: true }"
      >
        <vxe-table-column field="id" title="ID"  width="80"></vxe-table-column>
        <vxe-table-column field="name" title="接口名称" width="250"></vxe-table-column>
        <vxe-table-column field="method" title="请求方式" width="90" align="center"></vxe-table-column>
        <vxe-table-column field="apiKey" title="后端标识" min-width="250"></vxe-table-column>
        <vxe-table-column field="route" title="接口地址" min-width="300" tooltip="true"></vxe-table-column>
        <vxe-table-column field="apiKey" title="后端标志" min-width="250" tooltip="true"></vxe-table-column>
      </vxe-table>
      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0 mt-1">
          <Select v-model="searchParams.size" class="mr-1">
            <Option v-for="item in pageOptions" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
          <span class="text-nowrap"> 共 {{ total }} 条记录 </span>
        </div>
        <div class="d-flex align-items-center">
          <b-pagination v-model="searchParams.page" :total-rows="total" :per-page="searchParams.size" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
          <div class="d-flex align-items-center mb-0 mt-1 ml-1">
            <span class="text-nowrap">跳至</span>
            <Input @on-enter="pageToChange"
              v-model="pageTo" type="number" number class="mx-1" style="width: 5rem;"/>
            <span class="text-nowrap">页</span>
          </div>
        </div>
      </div>
    </b-card>
  </div>

</template>

<script>
import { ref, onMounted, watch } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { apiListApi } from '@/api/system/authority'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    ToastificationContent,
  },
  setup() {
    const loading = ref(false)
    const allAlign = ref(null)
    const tableData = ref([])

    const pageOptions = ref([
      { label: '10 条/页', value: 10 },
      { label: '20 条/页', value: 20 },
      { label: '50 条/页', value: 50 },
    ])
    // 数量
    const total = ref(0)
    const searchParams = ref({ page: 1, size: 10, apiKey: '', name	: '', route: '' })
    const pageTo = ref(null)
    // 指定页码操作
    const pageToChange = () => {
      const count = Math.ceil(total.value / searchParams.value.size)
      if (pageTo.value < 1) {
        pageTo.value = 1
      } else if (pageTo.value > count) {
        pageTo.value = count
      } else {
        pageTo.value = parseInt(pageTo.value, 0)
      }
      searchParams.value.page = pageTo.value
    }

    const fetchData = () => {
      apiListApi(searchParams.value)
        .then(res => {
          tableData.value = res.data.list
          total.value = res.data.total
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '获取Api列表失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }

    onMounted(() => { fetchData()  })
    // 刷新查询
    watch(searchParams.value, () => { fetchData() })

    return {
      loading,
      allAlign,
      tableData,

      pageOptions,
      pageToChange,
      total,
      pageTo,
      searchParams,
    }
  },
}
</script>
